
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 50px;
  }

  .App-header.chakra-demo{
    background-color: #282c34;
  }

  .App-header.shadcn-demo{
    background-color: #81858b;
  }
  
  .App-link {
    color: #61dafb;
  }

  .row {
    display: flex;
  }

  .row .col-6{
    width: 50%;
  }
  .css-dixmdy{
    min-width: fit-content !important;
  }
  .css-dixmdy h1{
    display:flex !important;
    color:#FFFFFF !important;
    max-width:auto !important;
    min-height:50px !important;
    padding:4px 8px !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    background: var(--Neutral-Bold, #171E22) !important;
    padding:4px 11px !important;
  }
  .customToast{
    display: flex !important;
    align-items: center !important;
    background: var(--Neutral-Bold, #171E22) !important;
    width: fit-content !important;
    padding:4px 11px !important;
    color: #fff !important;
    border-radius: 8px !important;
    min-height:50px !important;
  }
  .customToast button{
      background: transparent;
      margin: 5px;

  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .customToastStyle{
      background-color: "#171E22";
      color:"#FFFFFF";
      display: flex;
      max-width: 772px;
      min-height: 40px;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      /* background: var(--Neutral-Bold, #171E22) */
  }